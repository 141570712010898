import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
        <title>Cette page n'existe pas</title>
        <meta name="description" content="Cette page n'existe pas" />
      </Helmet>
    <div id="main">
      <h1>ERREUR 404 : Que faites-vous?!</h1>
      <div className="text-center">
      <p className="text-center">Il y a Allemand et Allemand. Allemand de l'ouest et Allemand de l'est. Allemand ami et Allemand ennemi. Mais tous ces Allemands sont Allemands. Qu'ils soient Allemands ou Allemands.</p>
      <a href="/#main" className="button text-center">Revenir en lieu sûr 🔒</a>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
